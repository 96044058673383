<template>
  <div>
    <div class="title">{{ $t("bigEvents") }}</div>
    <div v-if="associationData" v-loading="loading" class="associationData">
      <div class="content" v-html="associationData.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "河南省国际货运代理协会大事记",
      meta: [
        // {
        //   name: "keyWords",
        //   content: "上海市国际货运代理行业大事记,上海货代大事记",
        // },
        // {
        //   name: "description",
        //   content:
        //     "上 海 市 国 际 货 运 代 理 行 业 协 会 （ shanghai international freight forwarders association）是在我国改革开放不断深化，国际货运代 理业快速发展条件下，于 1992 年 9 月成立，是我国（除港澳台地区外）最早成立的省 市级国际货运代理行业协会。",
        // },
      ],
    };
  },
  data() {
    return {
      img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/Memorabilia.png",
      imgEn: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/MemorabiliaEn.png",
      loading: false,
      associationData: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      let params = {};
      params.nopage = 1;
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      params.content_type = 12;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.loading = false;
        this.associationData = res.data[0];
      }
    },
  },
};
</script>
<style scoped lang="less">
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
.associationData {
  .content {
    background: #ffffff;
    img {
      width: 100% !important;
    }
  }
}
</style>
